import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowsProp,
} from "@mui/x-data-grid";
import {
  MdEdit as EditIcon,
  MdSend as SendIcon,
  MdDeleteForever as DeleteIcon,
  MdExpandMore as ExpandIcon,
  MdClose as CloseIcon,
  MdFilterList,
} from "react-icons/md";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  adminUpdateUserFn,
  AdminUpdateUserInput,
  createUserFn,
  CreateUserInput,
  deleteUserFn,
  getUserRolesFn,
  getUsersFn,
  getUserStatusesFn,
  resendUserActivationEmailFn,
  unlockUserFn,
} from "src/api";
import LoadingIndicator from "src/components/LoadingIndicator";
import FilterableDataTable, {
  PaginationModel,
} from "src/dashboard/components/DataTable/FilterableDataTable";
import SectionContainer from "src/dashboard/components/SectionContainer";
import { countriesPhoneOptions } from "src/share/constants";
import { Filter, FilterColumns } from "src/share/types";
import { validateEmailField, validateRequiredField } from "src/share/utils";
import { queryClient } from "src/query-client";
import { useAuth } from "src/providers/AuthProvider";
import moment from "moment";
import { IoMdAdd, IoMdCheckmark, IoMdCheckmarkCircleOutline, IoMdClose, IoMdMore } from "react-icons/io";
import { Images } from "src/assets";
import { CiSearch } from "react-icons/ci";
import { decryptFn } from "src/share/utils/decrypt";
import { getInitialsAndColor } from "src/share/utils/getInitialsAndColor";

const initialVisibility = {
  userID: true,
  full_name: true,
  organization: true,
  phone_number: true,
  email: true,
  created_at: true,
  role: true,
  status: true,
  actions: true,
  enabled: false,
  numOrganizations: false,
  register_location: false,
  register_coordinates: false,
  register_ip: false,
  last_session_id: false,
  sequence_length_fail: false,
  symbol_location_fail: false,
  combo_fail: false,
};

const UsersPage = () => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [filteredRows, setFilteredRows] = useState<GridRowsProp>([]);
  const [filters, setFilters] = useState<Filter[]>([{ column: "", value: "" }, { column: "", value: "" }, { column: "", value: "" }]);
  const [rowCount, setRowCount] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");
  const [columnVisibility, setColumnVisibility] = useState(initialVisibility);

  const applyFilter = (data: GridRowsProp) => {
    if (!search) {
      setFilteredRows(data);
      return;
    }
    const filteredData = data.filter((row) =>
      row.first_name.toLowerCase().includes(search.toLowerCase()) || row.last_name.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredRows(filteredData);
  };

  const handleFilterChange = (
    index: number,
    field: keyof Filter,
    fieldVal: any,
    value: any
  ) => {

    setFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      newFilters[index][field] = (value === "All" || value === "") ? "" : fieldVal;
      newFilters[index].value = value === "All" ? "" : value;
      return newFilters;
    });
  };

  const handleApplyFilters = () => {
    const query = filters.reduce((currentQuery: any, filter) => {
      currentQuery[filter.column] = filter.value;
      return currentQuery;
    }, {});

    if (query.status === 'Active')
      query.status = 'active'
    else if (query.status === 'In Process')
      query.status = 'reg_process'
    else if (query.status === 'Locked')
      query.status = 'locked'
    else if (query.status === 'Disabled')
      query.status = 'disable'

    setSearchParams(query);
  };

  const clearAllFilters = () => {

    setSearchParams({ "": "" })
    setFilters([{ column: "", value: "" }, { column: "", value: "" }, { column: "", value: "" }]);
    handleFilterChange(0, "column", "", ""); // Creation Date
    handleFilterChange(1, "column", "", ""); // Role
    handleFilterChange(2, "column", "", ""); // Status
  };


  useEffect(() => {
    applyFilter(rows);
  }, [search, rows]);

  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [updateModalState, setUpdateModalState] = useState<{
    isOpen: boolean;
    user?: AdminUpdateUserInput;
  }>({ isOpen: false });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [organizationModalState, setOrganizationModalState] = useState<{
    isOpen: boolean;
    userId?: string | number;
    organizations?: Array<{
      id: number;
      name: string;
      groups: Array<{ id: number; name: string }>;
    }>;
  }>({ isOpen: false });
  const {
    isFetching,
    isError,
    data: usersData,
  } = useQuery({
    queryKey: [
      "users",
      paginationModel,
      Object.fromEntries(searchParams.entries()),
    ],
    queryFn: () =>
      getUsersFn({
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        query: Object.fromEntries(searchParams.entries()),
      }),
    keepPreviousData: true,
    staleTime: 1000 * 10,
  });
  const { isFetching: isRolesLoading, data: roles } = useQuery({
    queryKey: ["userRoles"],
    queryFn: getUserRolesFn,
  });
  const { isFetching: isUserStatusesLoading, data: userStatuses } = useQuery({
    queryKey: ["userStatuses"],
    queryFn: getUserStatusesFn,
  });
  const { isLoading: isUpdatingUser, mutate: adminUpdateUser } = useMutation(
    adminUpdateUserFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        handleCloseUpdateModal();
      },
    }
  );
  const { mutate: deleteUser, isLoading: isDeletingUser } = useMutation(
    deleteUserFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        alert("User is deleted");
      },
    }
  );
  const { mutate: unlockUser, isLoading: isUnlockUser } = useMutation(
    unlockUserFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        alert("User is unlocked");
      },
    }
  )
  const { mutate: resendActivationEmail, isLoading: isResending } = useMutation(
    resendUserActivationEmailFn,
    {
      onSuccess: () => {
        alert("Activation email is resent");
      },
    }
  );
  const { mutate: createUser, isLoading: isCreatingUser } = useMutation(
    createUserFn,
    {
      onSuccess: () => {
        handleCloseCreateModal();
        queryClient.invalidateQueries("users");
        alert("User is created");
      },
    }
  );

  function formatUsersData(usersData: any[]) {
    return usersData.map((user: any) => ({
      ...user,
      first_name: decryptFn(user.first_name),
      last_name: decryptFn(user.last_name),
      email: decryptFn(user.email),
      phone_number: decryptFn(user.phone_number),
      register_location: decryptFn(user.register_location),
      register_coordinates: decryptFn(user.register_coordinates),
      register_ip: decryptFn(user.register_ip)
    }));
  }


  useEffect(() => {
    if (!usersData) return;
    setRows(formatUsersData(usersData.data));
    setRowCount(usersData.meta.total);
  }, [usersData]);

  const initFilters = useMemo(() => {
    const query = searchParams;
    const newFilters = [];
    for (const col of Array.from(query.keys())) {
      const value = query.get(col);
      if (value === null) continue;
      newFilters.push({ column: col, value });
    }
    return newFilters;
  }, []);

  const handleOnApplyFilters = (filters: Filter[]) => {
    const query = filters.reduce((currentQuery: any, filter) => {
      currentQuery[filter.column] = filter.value;
      return currentQuery;
    }, {});
    // set the search query of the current URL
    setSearchParams(query);
  };

  const handleOpenUpdateModal = (user: AdminUpdateUserInput) => {
    setUpdateModalState({
      isOpen: true,
      user,
    });
  };

  const handleUnlockUser = (userId: number | string) => {
    unlockUser({ userId })
  }

  const handleDeleteUser =
    (userId: number | string, userName: string) => {
      if (
        !window.confirm(
          `Are you sure you want to delete ${userName}? This will delete all his data`
        )
      )
        return;
      deleteUser({ userId });
    };

  const handleCloseUpdateModal = () => {
    setUpdateModalState({ isOpen: false });
  };

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleResendActivationEmail = (userId: number | string) => () => {
    resendActivationEmail({ userId });
  };

  const handleOpenOrganizationsModal = (userId: number | string) => () => {
    const user = usersData?.data.find((user: any) => user.userID === userId);
    if (!user || user.organizations.length === 0) return;
    // open the organizations modal
    setOrganizationModalState({
      isOpen: true,
      userId: userId,
      organizations: user.organizations,
    });
  };

  const handleCloseOrganizationsModal = () => {
    setOrganizationModalState({
      isOpen: false,
    });
  };

  const ActionsCell: React.FC<{ row: AdminUpdateUserInput }> = ({ row }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };
    const userName = [row.first_name, row.last_name].join(" ");

    return (
      <>
        <IconButton aria-controls="more-menu" aria-haspopup="true" onClick={handleMenuClick}>
          <IoMdMore size={32} />
        </IconButton>
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {/* {row.status !== "Locked" && <MenuItem sx={{ padding: '14px' }} onClick={() => { handleUnlockClick(row.userId, row.groupId); }}>
            <img style={{ marginRight: '12px' }} src={Images.icLock} width="20" height="20" />
            Unlock
          </MenuItem>} */}
          {row.status == "reg_by_admin" &&
            <MenuItem sx={{ padding: '14px' }} onClick={() => { handleResendActivationEmail(row.userID) }}>
              <img style={{ marginRight: '12px' }} src={Images.icSend} width="20" height="20" />
              Send Email
            </MenuItem>}
          <MenuItem sx={{ padding: '14px' }} onClick={() => { handleOpenUpdateModal(row); }}>
            <img style={{ marginRight: '12px' }} src={Images.icEdit} width="20" height="20" />
            Edit User
          </MenuItem>
          <MenuItem sx={{ padding: '14px' }} onClick={() => { handleDeleteUser(row.userID, userName); }}>
            <img style={{ marginRight: '12px' }} src={Images.icTrash} width="20" height="20" />
            Delete User
          </MenuItem>
          {row.status === "locked" &&
            <MenuItem sx={{ padding: '14px' }} onClick={() => { handleUnlockUser(row.userID) }}>
              <img style={{ marginRight: '12px' }} src={Images.icLock} width="20" height="20" />
              Unlock User
            </MenuItem>}
        </Menu>
      </>
    );
  };

  const filterColumns: FilterColumns = [
    {
      name: "userID",
      type: "number",
      label: "ID",
    },
    {
      name: "first_name",
      type: "string",
      label: "First Name",
    },
    {
      name: "last_name",
      type: "string",
      label: "Last Name",
    },
    {
      name: "country_code",
      type: "singleSelect",
      label: "Country Code",
      options: countriesPhoneOptions,
    },
    {
      name: "phone_number",
      type: "string",
      label: "Phone Number",
    },
    {
      name: "created_at",
      type: "timestamp",
      label: "Created At",
    },
    {
      name: "enabled",
      type: "boolean",
      label: "Enabled",
    },
    {
      name: "roleID",
      type: "singleSelect",
      label: "Role Name",
      options: (roles ?? []).map((role) => ({
        value: role.id,
        label: role.role,
      })),
    },
    {
      name: "email",
      type: "string",
      label: "Email",
    },
    {
      name: "register_location",
      type: "string",
      label: "Register Location",
    },
    {
      name: "register_coordinates",
      type: "string",
      label: "Register Coordinates",
    },
    {
      name: "register_ip",
      type: "string",
      label: "Register IP",
    },
    {
      name: "status",
      type: "string",
      label: "Status",
    },
    {
      name: "last_session_id",
      type: "number",
      label: "Last Session ID",
    },
    {
      name: "sequence_length_fail",
      type: "number",
      label: "Sequence Length Fail",
    },
    {
      name: "symbol_location_fail",
      type: "number",
      label: "Symbol Location Fail",
    },
    {
      name: "combo_fail",
      type: "number",
      label: "Combo Fail",
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "userID",
      headerName: "Id",
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      width: 60,
      headerAlign: "left",
    },
    {
      field: "full_name",
      headerName: "Full Name",
      width: 160,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
      renderCell: (params) => {
        const firstName = params.row.first_name || 'User'
        const lastName = params.row.last_name || 'User'
        const { initials, color } = getInitialsAndColor(firstName, lastName)
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={params.row.image}
              sx={{
                width: '36px',
                height: '36px',
                marginRight: '8px',
                borderRadius: '50%',
                color: '#36454f',
                backgroundColor: params.row.image ? 'transparent' : color,
              }}>
                {(!params.row.image) && initials}
              </Avatar>
            {params.row.first_name} {params.row.last_name}
          </div>
        )
      },
    },
    {
      field: "organization",
      headerName: "Organization",
      width: 140,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 160,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "email",
      headerName: "Email",
      width: 190,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "enabled",
      headerName: "Enabled",
      type: "boolean",
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "numOrganizations",
      headerName: "No. of Organizations",
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
      width: 130,
      renderCell: (params) => {
        const numOrgs = params.row.organizations.length;
        return (
          <Box
            onClick={
              numOrgs > 0
                ? handleOpenOrganizationsModal(params.row.userID)
                : undefined
            }
            sx={{
              cursor: numOrgs > 0 ? "pointer" : "default",
              color: numOrgs > 0 ? "blue" : "inherit",
              "&:hover": {
                textDecoration: numOrgs > 0 ? "underline" : "none",
              },
            }}
          >
            {numOrgs}
          </Box>
        );
      },
    },
    {
      field: "created_at",
      width: 190,
      headerName: "Created At",
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
      renderCell: (params) => {
        return new Date(params.value).toLocaleString();
      },
    },
    {
      field: "register_location",
      headerName: "Register Location",
      width: 200,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "register_coordinates",
      headerName: "Register Coordinates",
      width: 200,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "register_ip",
      headerName: "Register IP",
      width: 150,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "role",
      headerName: "Role",
      width: 90,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
      renderCell: (params) => {
        const status = params.value as string;
        let backgroundColor, color = '';
        if (status === 'active') {
          backgroundColor = '#F4FCF1';
          color = '#008E0F';
        } else if (status === 'locked' || status === 'self_deleted') {
          backgroundColor = '#FFE7E7';
          color = '#EC1616';
        } else if (status === 'frozen') {
          backgroundColor = '#F9F8FF';
          color = '#919099';
        } else {
          backgroundColor = '#FFF7E7';
          color = '#ECA316';

        }
        return (
          <div style={{ backgroundColor, color, padding: '5px', borderRadius: '100px' }}>
            {status}
          </div>
        );
      }
    },
    {
      field: "last_session_id",
      headerName: "Last Session ID",
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "sequence_length_fail",
      headerName: "Sequence Length Fail",
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "symbol_location_fail",
      headerName: "Symbol Location Fail",
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "combo_fail",
      headerName: "Combo Fail",
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 80,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
      renderCell: (params) => (
        <ActionsCell row={params.row as AdminUpdateUserInput} />
      ),
    },
    {
      field: "",
      headerName: "",
      width: 80,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'center',
      headerAlign: "center",
      renderHeader: () => <ColumnMenuIcon columnVisibility={columnVisibility} setColumnVisibility={setColumnVisibility} columns={columns} />

    },
  ];

  interface ColumnMenuIconProps {
    columnVisibility: { [key: string]: boolean };
    setColumnVisibility: any;
    columns: GridColDef[];
  }

  const ColumnMenuIcon: React.FC<ColumnMenuIconProps> = ({ columnVisibility, setColumnVisibility, columns }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleToggleColumn = (field: string) => {
      setColumnVisibility((prevVisibility: any) => ({
        ...prevVisibility,
        [field]: !prevVisibility[field],
      }));
    };

    const visibleColumns = columns.filter(column => columnVisibility[column.field] !== false && column.field !== '' && column.field !== 'actions');
    const invisibleColumns = columns.filter(column => columnVisibility[column.field] === false && column.field !== '' && column.field !== 'actions');

    return (
      <div>
        <IconButton onClick={handleClick}>
          <IoMdMore size={24} />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Visible Columns</Typography>
          </MenuItem>
          {visibleColumns.map((column) => (
            <MenuItem sx={{ width: '227px', display: 'flex', justifyContent: 'space-between' }} key={column.field} onClick={() => handleToggleColumn(column.field)}>
              {column.headerName || column.field}
              <IoMdCheckmark />
            </MenuItem>
          ))}
          <Divider />
          {invisibleColumns.map((column) => (
            <MenuItem key={column.field} onClick={() => handleToggleColumn(column.field)}>
              {column.headerName || column.field}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };


  if (isError) {
    return (
      <SectionContainer>
        <Typography variant="h4">Trouble loading users</Typography>
      </SectionContainer>
    );
  }

  const rowsData = filteredRows.map(row => ({
    ...row,
    organization: row.organizations.map((org: any) => org.name),
    phone_number: `${row.country_code} ${row.phone_number}`,
    createDate: moment(row.createDate).format('DD/MM/YYYY, hh:mm A'),
    isOrgAdmin: row.isOrgAdmin ? 'org_admin' : 'sym_user'
  }));


  return (
    <>
      <Box sx={{
        height: 600,
        marginTop: 5,
        marginBottom: 10,
        width: 'fit-content',
        maxWidth: '100%',
        '& .column-style': {
          backgroundColor: '#F3F2F7',
          fontWeight: "bold"
        },
        '& .row-style': {
          color: '#111111',
          fontSize: '14px'
        },

      }}>
        <Box className="header-group">

          <div className="product-search">
            <CiSearch size={20} />
            <input
              type="text"
              placeholder="Search user"
              className="products__search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
          </div>
          <UsersToolbar
            filters={filters}
            handleFilterChange={handleFilterChange}
            clearAllFilters={clearAllFilters}
            handleApplyFilters={handleApplyFilters}
            handleCreateClick={handleOpenCreateModal} />
        </Box>
        <FilterableDataTable
          loading={isFetching || isDeletingUser || isResending}
          initFilters={initFilters}
          columns={columns}
          rows={rowsData}
          rowCount={rowCount}
          filterColumns={filterColumns}
          paginationModel={paginationModel}
          onPaginationChange={setPaginationModel}
          onApplyFilters={handleOnApplyFilters}
          columnVisibilityModel={columnVisibility}
          getRowId={(row) => row.userID}
        />
      </Box>
      <CreateNewUserModal
        isOpen={isCreateModalOpen}
        handleClose={handleCloseCreateModal}
        handleCreateUser={createUser}
        isSubmitting={isCreatingUser}
      />
      <EditUserFormModal
        isOpen={updateModalState.isOpen}
        isUserRolesLoading={isRolesLoading}
        isUserStatusesLoading={isUserStatusesLoading}
        roles={roles ?? []}
        statuses={userStatuses ?? []}
        user={updateModalState.user}
        handleUpdateUser={adminUpdateUser}
        handleCancel={handleCloseUpdateModal}
        isSubmitting={isUpdatingUser}
      />
      <OrganizationsModal
        isOpen={organizationModalState.isOpen}
        userId={organizationModalState.userId}
        organizations={organizationModalState.organizations ?? []}
        handleClose={handleCloseOrganizationsModal}
      />
    </>
  );
};

interface UsersToolbarProps {
  handleCreateClick: () => void;
  filters: Filter[];
  handleFilterChange: (index: number, field: keyof Filter, fieldVal: any, value: any) => void;
  handleApplyFilters: () => void;
  clearAllFilters: () => void;

}



function UsersToolbar({
  handleCreateClick,
  filters,
  handleApplyFilters,
  handleFilterChange,
  clearAllFilters
}: UsersToolbarProps) {

  const [filterAnchorEl, setFilterAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const filterOpen = Boolean(filterAnchorEl);

  const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterAnchorEl(null);
  };

  const columnNames = ["createData", "role_name", "status"];

  const handleChipDelete = (chipValue: string, filterIndex: number) => {
    const newValue = filters[filterIndex].value
      .split(",")
      .filter((value: string) => value !== chipValue)
      .join(",");

    handleFilterChange(filterIndex, "column", columnNames[filterIndex], newValue);
  };

  const renderFilters = () => {

    return (
      <Box sx={{ display: 'flex', flexDirection: "column", gap: '40px', width: '100%' }}>
        <Box sx={{ cursor: "pointer", }} onClick={clearAllFilters}>
          <Typography textAlign="right" sx={{ textDecoration: "underline" }} color="#7694FF">Clear All</Typography>
        </Box>
        <FormControl size="small" margin="none" fullWidth>
          <InputLabel>Creation Date</InputLabel>
          <Select
            fullWidth
            multiple
            displayEmpty
            label={'Creation Date'}
            value={filters[0].value.split(',')}
            renderValue={(selected) => {
              if (selected.length === 1 && selected[0] == "") {
                return 'All';
              }
              return `${selected.length} selected`;
            }}
            onChange={(event) => {
              const selectedValues = event.target.value as string[];
              let newValue = selectedValues.join(',').replace(/^,/, '');
              if (selectedValues.indexOf("") !== 0 && selectedValues.indexOf("") !== -1) {
                newValue = "";
              }

              handleFilterChange(0, "column", "createData", newValue);
            }}
          >
            <MenuItem value="">All</MenuItem>
            {[{ value: 'today', label: "Today" }, { value: 'yesterday', label: "Yesterday" }, { value: 'lastWeek', label: "Last Week" }, { value: 'lastMonth', label: "Last Month" }].map((col, index) => (
              <MenuItem value={col.value} key={index}>
                {col.label}
              </MenuItem>
            ))}
          </Select>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {filters[0].value.split(",").map((value: any) => {
              if (value != "")
                return (
                  <Chip
                    deleteIcon={<img width="14" height="14" src={Images.icClose} />}
                    key={value}
                    label={value}
                    onDelete={() => handleChipDelete(value, 0)}
                    style={{ justifyContent: 'space-between', width: '150px', background: '#E9EEF8', margin: '10px 12px 0 0', paddingRight: '10px' }}
                  />
                );
            })}
          </div>
        </FormControl>

        <FormControl size="small" margin="none" fullWidth>
          <InputLabel id="role-label">Role</InputLabel>
          <Select
            labelId="role-label"
            fullWidth
            multiple
            displayEmpty
            label="Role"
            value={filters[1].value.split(',')}
            renderValue={(selected) => {
              if (selected.length === 1 && selected[0] == "") {
                return 'All';
              }
              return `${selected.length} selected`;
            }}
            onChange={(event) => {
              const selectedValues = event.target.value as string[];
              let newValue = selectedValues.join(',').replace(/^,/, '');
              if (selectedValues.indexOf("") !== 0 && selectedValues.indexOf("") !== -1) {
                newValue = "";
              }

              handleFilterChange(1, "column", "role_name", newValue);
            }}
          >
            <MenuItem value="">All</MenuItem>
            {["sym_user", "sym_admin", "app_user"].map((col, index) => (
              <MenuItem value={col} key={index}>
                {col}
              </MenuItem>
            ))}
          </Select>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {filters[1].value.split(",").map((value: any) => {
              if (value != "")
                return (
                  <Chip
                    deleteIcon={<img width="14" height="14" src={Images.icClose} />}
                    key={value}
                    label={value}
                    onDelete={() => handleChipDelete(value, 1)}
                    style={{ justifyContent: 'space-between', width: '150px', background: '#E9EEF8', margin: '10px 12px 0 0', paddingRight: '10px' }}
                  />
                );
            })}
          </div>
        </FormControl>

        <FormControl size="small" margin="none" fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            fullWidth
            multiple
            label="Status"
            displayEmpty
            value={filters[2].value.split(',')}
            renderValue={(selected) => {
              if (selected.length === 1 && selected[0] == "") {
                return 'All';
              }
              return `${selected.length} selected`;
            }}
            onChange={(event) => {
              const selectedValues = event.target.value as string[];
              let newValue = selectedValues.join(',').replace(/^,/, '');
              if (selectedValues.indexOf("") !== 0 && selectedValues.indexOf("") !== -1) {
                newValue = "";
              }

              handleFilterChange(2, "column", "status", newValue);
            }}
          >
            <MenuItem value="">All</MenuItem>
            {["reg_process", "hybrid_reg_process", "locked", "active", "reg_by_admin", "self_deleted"].map((col, index) => (
              <MenuItem value={col} key={index}>
                {col}
              </MenuItem>
            ))}
          </Select>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {filters[2].value.split(",").map((value: any) => {
              if (value != "")
                return (
                  <Chip
                    deleteIcon={<img width="14" height="14" src={Images.icClose} />}
                    key={value}
                    label={value}
                    onDelete={() => handleChipDelete(value, 2)}
                    style={{ justifyContent: 'space-between', width: '150px', background: '#E9EEF8', margin: '10px 12px 0 0', paddingRight: '10px' }}
                  />
                );
            })}
          </div>
        </FormControl>
      </Box>
    );
  };

  return (
    <Box sx={{ marginBottom: 1 }}>
      <Button
        startIcon={<MdFilterList size={20} />}
        sx={{ color: '#303030', marginRight: 1 }}
        onClick={handleOpenFilter}
      >
        Filter
      </Button>
      <Button
        sx={{
          width: '122px',
          "&:hover": { backgroundColor: "#303030" },
          backgroundColor: "#303030"
        }}
        variant="contained" onClick={handleCreateClick}>
        New User
      </Button>
      <Modal open={filterOpen}>
        <Box
          sx={{
            width: '35%',
            backgroundColor: "white",
            position: "absolute",
            height: '100%',
            top: 0,
            right: 0,
          }}
        >
          <Box className="header-modal">
            <Typography variant="h6" component="h2">
              Filter
            </Typography>
            <IoMdClose onClick={handleCloseFilter} size={24} />
          </Box>

          <Box sx={{ width: "100%", padding: '60px' }}>
            <Grid container spacing={2}>
              {renderFilters()}
              <Grid item xs={12}>
                <Box sx={{ left: 0, width: '100%', bottom: 0, position: 'absolute' }}>
                  <Divider sx={{ background: '#DCDBE8' }} />
                  <Box sx={{ padding: '12px 60px', justifyContent: 'space-between', display: 'flex' }}>
                    <Button
                      variant="text"
                      onClick={handleCloseFilter}
                      sx={{ color: '#303030' }}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        width: '97px !important',
                        "&:hover": { backgroundColor: "#111111" },
                        backgroundColor: "#111111",
                      }}
                      className="button-group"
                      variant="contained"
                      type="submit"
                      onClick={() => {
                        handleApplyFilters()
                        handleCloseFilter()
                      }}
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

interface CreateNewUserModalProps {
  isOpen: boolean;
  handleClose: () => void;
  isSubmitting: boolean;
  handleCreateUser: (user: CreateUserInput) => Promise<void> | void;
}

function CreateNewUserModal({
  isSubmitting,
  handleClose,
  handleCreateUser,
  isOpen,
}: CreateNewUserModalProps) {
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit: formHandleSubmit,
    getValues,
    reset,
    control,
  } = useForm<CreateUserInput>();

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  const sendSubmit = async () => {
    if (!isValid) {
      return;
    }
    const newUser = getValues();
    await handleCreateUser(newUser);
  };

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          width: '35%',
          backgroundColor: "white",
          position: "absolute",
          height: '100%',
          top: 0,
          right: 0,
        }}
      >
        <Box className="header-modal">
          <Typography variant="h6" component="h2">
            New User
          </Typography>
          <IoMdClose onClick={handleClose} size={24} />
        </Box>
        <form style={{ padding: '60px', width: '100%' }} onSubmit={formHandleSubmit(sendSubmit)}>
          <TextField
            {...register("first_name", {
              required: true,
              validate: validateRequiredField,
            })}
            helperText={errors.first_name && errors.first_name.message}
            error={!!errors.first_name}
            label="First Name"
            fullWidth
            margin="normal"
          />
          <TextField
            {...register("last_name", {
              required: true,
              validate: validateRequiredField,
            })}
            helperText={errors.last_name && errors.last_name.message}
            error={!!errors.last_name}
            label="Last Name"
            fullWidth
            sx={{ marginTop: 3 }}
            margin="normal"
          />
          <Controller
            name="country_code"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onBlur, onChange, ref } }) => (
              <Autocomplete
                options={countriesPhoneOptions}
                getOptionLabel={(option) => option.label}
                ref={ref}
                value={countriesPhoneOptions.find(
                  (option) =>
                    option.value === value || `+${option.value}` === value
                )}
                onChange={(_, newValue) => {
                  onChange(newValue?.value);
                }}
                onBlur={onBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ marginTop: 3 }}
                    label="Country code"
                    error={errors.country_code ? true : false}
                    helperText={
                      errors.country_code ? errors.country_code.message : null
                    }
                    margin="normal"
                  />
                )}
              />
            )}
          />
          <TextField
            {...register("phone_number", {
              required: true,
            })}
            helperText={errors.phone_number && errors.phone_number.message}
            error={!!errors.phone_number}
            label="Phone"
            fullWidth
            sx={{ marginTop: 3 }}
            margin="normal"
          />
          <TextField
            {...register("email", {
              required: true,
              validate: validateEmailField,
            })}
            helperText={errors.email && errors.email.message}
            error={!!errors.email}
            label="Email"
            fullWidth
            sx={{ marginTop: 3 }}
            margin="normal"
          />
          <Box sx={{ left: 0, width: '100%', bottom: 0, position: 'absolute' }}>
            <Divider sx={{ background: '#DCDBE8' }} />
            <Box sx={{ padding: '12px 60px', justifyContent: 'space-between', display: 'flex' }}>
              <Button
                variant="text"
                onClick={handleClose}
                sx={{ color: '#303030' }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: '97px !important',
                  "&:hover": { backgroundColor: "#111111" },
                  backgroundColor: "#111111",
                }}
                variant="contained"
                type="submit"
                disabled={!isDirty || isSubmitting}
              >
                {'Create'}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}

interface EditUserFormModalProps {
  isOpen: boolean;
  handleUpdateUser: (user: AdminUpdateUserInput) => Promise<void> | void;
  handleCancel: () => void;
  roles: Array<{ id: number; role: string }>;
  statuses: Array<{ id: number; status: string }>;
  user: AdminUpdateUserInput | undefined;
  isUserRolesLoading: boolean;
  isUserStatusesLoading: boolean;
  isSubmitting: boolean;
}

function EditUserFormModal({
  isOpen,
  handleUpdateUser,
  handleCancel,
  isSubmitting,
  roles,
  statuses,
  user,
  isUserRolesLoading,
  isUserStatusesLoading,
}: EditUserFormModalProps) {
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit: formHandleSubmit,
    getValues,
    reset,
    control,
  } = useForm<AdminUpdateUserInput>({
    defaultValues: {
      ...user,
      status: statuses.find(
        (status) => user && status.status === (user.status as any)
      )?.id,
    },
  });

  useEffect(() => {
    reset({
      ...user,
      status: statuses.find(
        (status) => user && status.status === (user.status as any)
      )?.id,
    });
  }, [reset, statuses, user, isOpen]);

  if (!user) {
    return null;
  }

  const sendSubmit = async () => {
    if (!isValid) {
      return;
    }
    const updatedUser = getValues();
    await handleUpdateUser(updatedUser);
  };

  const roleOptions = roles.map((role) => ({
    value: role.id,
    label: role.role,
  }));

  const statusOptions = statuses.map((status) => ({
    value: status.id,
    label: status.status,
  }));

  if (isUserRolesLoading || isUserStatusesLoading) {
    return (
      <Modal open={isOpen}>
        <Box
          sx={{
            width: 500,
            backgroundColor: "white",
            p: 2,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 1,
          }}
        >
          <LoadingIndicator variant="fullWidth" />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          width: '35%',
          backgroundColor: "white",
          position: "absolute",
          height: '100%',
          top: 0,
          right: 0,
        }}
      >
        <Box className="header-modal">
          <Typography variant="h6" component="h2">
            Edit User
          </Typography>
          <IoMdClose onClick={handleCancel} size={24} />
        </Box>
        <form style={{ padding: '60px', width: '100%' }} onSubmit={formHandleSubmit(sendSubmit)}>
          {/* <Typography variant="body1">User ID: {user.userID}</Typography> */}
          <TextField
            {...register("first_name", {
              required: true,
              validate: validateRequiredField,
            })}
            helperText={errors.first_name && errors.first_name.message}
            error={!!errors.first_name}
            label="First Name"
            fullWidth
            margin="normal"
          />
          <TextField
            {...register("last_name", {
              required: true,
              validate: validateRequiredField,
            })}
            helperText={errors.last_name && errors.last_name.message}
            error={!!errors.last_name}
            label="Last Name"
            fullWidth
            sx={{ marginTop: 3 }}
            margin="normal"
          />
          <Controller
            name="country_code"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onBlur, onChange, ref } }) => (
              <Autocomplete
                options={countriesPhoneOptions}
                getOptionLabel={(option) => option.label}
                ref={ref}
                value={countriesPhoneOptions.find(
                  (option) =>
                    option.value === value || `+${option.value}` === value
                )}
                onChange={(_, newValue) => {
                  onChange(newValue?.value);
                }}
                onBlur={onBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country code"
                    sx={{ marginTop: 3 }}
                    error={errors.country_code ? true : false}
                    helperText={
                      errors.country_code ? errors.country_code.message : null
                    }
                    margin="normal"
                  />
                )}
              />
            )}
          />
          <TextField
            {...register("phone_number", {
              required: true,
            })}
            helperText={errors.phone_number && errors.phone_number.message}
            error={!!errors.phone_number}
            label="Phone"
            fullWidth
            sx={{ marginTop: 3 }}
            margin="normal"
          />
          <TextField
            {...register("email", {
              required: true,
              validate: validateEmailField,
            })}
            helperText={errors.email && errors.email.message}
            error={!!errors.email}
            label="Email"
            sx={{ marginTop: 3 }}
            fullWidth
            margin="normal"
          />
          {/* <Controller
            name="roleID"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onBlur, onChange, ref } }) => (
              <Autocomplete
                options={roleOptions}
                getOptionLabel={(option) => option.label}
                ref={ref}
                onChange={(_, newValue) => {
                  onChange(newValue?.value);
                }}
                onBlur={onBlur}
                value={roleOptions.find((option) => option.value === value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Role"
                    error={errors.roleID ? true : false}
                    helperText={errors.roleID ? errors.roleID.message : null}
                    margin="normal"
                  />
                )}
              />
            )}
          />
          <Controller
            name="status"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onBlur, onChange, ref } }) => (
              <Autocomplete
                options={statusOptions}
                getOptionLabel={(option) => option.label}
                ref={ref}
                onChange={(_, newValue) => {
                  onChange(newValue?.value);
                }}
                onBlur={onBlur}
                value={statusOptions.find((option) => option.value === value)}
                isOptionEqualToValue={(option) => option.value === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    error={errors.status ? true : false}
                    helperText={errors.status ? errors.status.message : null}
                    margin="normal"
                  />
                )}
              />
            )}
          />
          <TextField
            {...register("sequence_length_fail", {
              required: true,
              min: {
                value: 0,
                message: "Sequence Length Fail cannot be smaller than 0",
              },
              valueAsNumber: true,
            })}
            type="number"
            helperText={
              errors.sequence_length_fail && errors.sequence_length_fail.message
            }
            error={!!errors.sequence_length_fail}
            label="Sequence Length Fail"
            fullWidth
            margin="normal"
          />
          <TextField
            {...register("symbol_location_fail", {
              required: true,
              min: {
                value: 0,
                message: "Symbol Location Fail cannot be smaller than 0",
              },
              valueAsNumber: true,
            })}
            type="number"
            helperText={
              errors.symbol_location_fail && errors.symbol_location_fail.message
            }
            error={!!errors.email}
            label="Symbol Location Fail"
            fullWidth
            margin="normal"
          />
          <TextField
            {...register("combo_fail", {
              required: true,
              min: {
                value: 0,
                message: "Combo Fail cannot be smaller than 0",
              },
              valueAsNumber: true,
            })}
            type="number"
            helperText={errors.combo_fail && errors.combo_fail.message}
            error={!!errors.combo_fail}
            label="Combo Fail"
            fullWidth
            margin="normal"
          />
          <Controller
            name="enabled"
            control={control}
            rules={{ required: false }}
            render={({ field: { value, onChange } }) => (
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(value)}
                      onChange={(e) => onChange(Boolean(e.target.checked))}
                    />
                  }
                  label="Is Enabled"
                />
              </Box>
            )}
          /> */}
          <Box sx={{ left: 0, width: '100%', bottom: 0, position: 'absolute' }}>
            <Divider sx={{ background: '#DCDBE8' }} />
            <Box sx={{ padding: '12px 60px', justifyContent: 'space-between', display: 'flex' }}>
              <Button
                variant="text"
                onClick={handleCancel}
                sx={{ color: '#303030' }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: '148px !important',
                  "&:hover": { backgroundColor: "#111111" },
                  backgroundColor: "#111111",
                }}
                variant="contained"
                type="submit"
                disabled={!isDirty || isSubmitting}
              >
                {'Save Changes'}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}

interface OrganizationsModalProps {
  isOpen: boolean;
  userId?: string | number;
  organizations: Array<{
    id: number;
    name: string;
    groups: Array<{ id: number; name: string }>;
  }>;
  handleClose: () => void;
}

function OrganizationsModal({
  isOpen,
  userId,
  organizations,
  handleClose,
}: OrganizationsModalProps) {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleNavigateToUserGroup = (orgId: number, groupId: number) => () => {
    if (!user || userId === undefined) return;
    navigate(
      `/${user?.id}/sym-admin/organizations/${orgId}?groupID=${groupId}&userID=${userId}`,
      { state: { defaultTab: "users" } }
    );
  };

  const handleNavigateToUserOrganization = (orgId: number) => () => {
    if (!user || userId === undefined) return;
    navigate(`/${user?.id}/sym-admin/organizations/${orgId}?userID=${userId}`, {
      state: { defaultTab: "users" },
    });
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          width: 500,
          backgroundColor: "white",
          p: 2,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <Typography variant="h6" component="h2">
            Organizations
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon size={20} />
          </IconButton>
        </Box>
        {organizations.map((org) => (
          <Accordion key={org.id}>
            <AccordionSummary expandIcon={<ExpandIcon size={20} />}>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "blue",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={handleNavigateToUserOrganization(org.id)}
              >
                {org.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="subtitle1">Groups</Typography>
              {org.groups.map((group) => (
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    cursor: "pointer",
                    color: "blue",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  onClick={handleNavigateToUserGroup(org.id, group.id)}
                  key={group.id}
                >
                  {group.name}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Modal>
  );
}

export default UsersPage;
